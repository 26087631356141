
.icon {
  width: 65px;
  height: 65px;
  border-radius: 16px;
  box-shadow: 0px 0px 16px #00000020;
}

/* desktop */
@media(min-width: 570px) {
  .icon {
    width: 120px;
    height: 120px;
  }
}

.appName {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 19.5pt;
}

/* desktop */
@media(min-width: 570px) {
  .appName {
    font-size: 33pt;
  }
}

.tagline {
  font-size: 15pt;
}