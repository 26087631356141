h3 {
    margin-top: 32px;
}

p {
    margin-top: 16px;
    margin-bottom: 16px;
}

li {
    margin-left: 32px;
}

[data-custom-class='body'],
[data-custom-class='body'] * {
    background: transparent !important;
}

[data-custom-class='title'],
[data-custom-class='title'] * {
    font-size: 33px !important;
    color: black !important;
}

[data-custom-class='subtitle'],
[data-custom-class='subtitle'] * {
    color: black !important;
    font-size: 21px !important;
}

[data-custom-class='heading_1'],
[data-custom-class='heading_1'] * {
    font-size: 26px !important;
    color: black !important;
}

[data-custom-class='heading_2'],
[data-custom-class='heading_2'] * {
    font-size: 24px !important;
    color: black !important;
}

[data-custom-class='body_text'],
[data-custom-class='body_text'] * {
    color: black !important;
    font-size: 16px !important;
}

[data-custom-class='link'],
[data-custom-class='link'] * {
    color: black !important;
    font-size: 16px !important;
    word-break: break-word !important;
}

ul {
    list-style-type: square;
}

ul>li>ul {
    list-style-type: circle;
}

ul>li>ul>li>ul {
    list-style-type: square;
}

ol li {
    font-family: Arial;
}
