@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&family=Lato&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #333333;
  color: white;
  font-family: 'Lato', sans-serif;
}

a {
  text-decoration: none;
  font-weight: bold;
  color: white;
}


.navBar_navBar__3SOKn {
  padding-top: 16px;
  padding-bottom: 8px;
  width: 100%;
  height: 60px;
  display: flex;
}

.appName_appName__3iRqS {
  font-weight: bold;
  font-size: 18pt;
  color: black;
}

.appName_appIcon__3tfl0 {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 0px 8px #00000015;
}

.row_row__3pNrK {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.constrainedWidth_constrainedWidth__3peLt {
  width: 100%;
  height: 100%;
}

.column_column__2JDOj {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.expanded_expanded__3a06Q {
  flex-grow: 1;
}

.page_page__1fuz- {
  width: 100%;
  position: relative;
}

.page_title__3ba_T {
  font-size: 25pt;
  margin-bottom: 8px;
  font-family: 'Josefin Sans', sans-serif;
  text-shadow: 0px 0px 8px #00000015;
}

/* desktop */

@media(min-width: 570px) {
  .page_title__3ba_T {
    font-size: 35pt;
  }
}

.page_border__34oq- {
  position: absolute;
  width: 100%;
  transform: translate(0px, -50%);
  z-index: 0;
}


.heroText_hero__XxQzn {
  width: 100%;
}

.stats_statHeader__3ANkk {
  font-weight: bold;
  font-size: max(9.0vw, 15pt);
  text-align: center;
}

@media(min-width: 570px) {
  .stats_statHeader__3ANkk {
    font-size: max(min(4.0vw, 40pt), 30pt);
  }
}

.stats_statSubheader__Lghyi {
  font-size: inherit;
  text-align: center;
}

.stats_verticalDivider__3U3We {
  height: 80px;
  width: 5px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.65);
}

@media(min-width: 570px) {
  .stats_verticalDivider__3U3We {
    width: 6px;
    height: 110px;
  }
}

.stats_star__1U3Av {
  height: 23px;
}

@media(min-width: 570px) {
  .stats_star__1U3Av {
    height: 43px;
  }
}

.center_center__3amAe {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.downloadCallToAction_badge__2gXAw {
  height: 58px;
  object-fit: contain;
}

/* Desktop */
@media(min-width: 570px) {
  .downloadCallToAction_badge__2gXAw {
    height: 70px;
  }
}


.features_pencil__xS7XZ {
  height: 27px;
  fill: white
}

.features_featureText__11qDn {
  text-align: center;
  display: inline-block;
}

.appIcon_icon__2wHo- {
  width: 65px;
  height: 65px;
  border-radius: 16px;
  box-shadow: 0px 0px 16px #00000020;
}

/* desktop */
@media(min-width: 570px) {
  .appIcon_icon__2wHo- {
    width: 120px;
    height: 120px;
  }
}

.appIcon_appName__29Mbs {
  font-family: 'Josefin Sans', sans-serif;
  font-size: 19.5pt;
}

/* desktop */
@media(min-width: 570px) {
  .appIcon_appName__29Mbs {
    font-size: 33pt;
  }
}

.appIcon_tagline__2pwjO {
  font-size: 15pt;
}

.heroPhone_phone__P49br {
  margin-right: 4px;
  max-height: 70vh;
  object-fit: contain;
  width: 85%;
}

.drawings_carouselContainer__164xX {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  white-space: nowrap;
  /* Hide scroll bars */
  -ms-overflow-style: none;
}

.drawings_carouselContainer__164xX::-webkit-scrollbar {
  display: none;
}

.drawings_call_to_action__2oBJC {
  text-align: center;
}

.drawing_drawings__UCrA7 {
  background-color: #FAC130;
}

.drawing_carouselContainer__3lq_0 {
  overflow-x: scroll;
  width: 100%;
  white-space: nowrap;
  /* Hide scroll bars */
  -ms-overflow-style: none;
}

.drawing_carouselContainer__3lq_0::-webkit-scrollbar {
  display: none;
}

.drawing_drawingContainer__3HgI2 {
  display: inline-block;
}

.drawing_drawingTitle__1AIUH {
  font-weight: bold;
}

.drawing_drawing__3u9hY {
  background-color: white;
  border-radius: 8px;

  min-width: 180px;
  min-height: 180px;

  max-width: 400px;
  max-height: 400px;

  box-shadow: 0px 0px 8px #00000015;
}

.drawing_drawingDesktop__CXerw {
  width: 25vw;
  height: 25vw;
}

.drawing_drawingPhone__3luPn {
  width: 75vw;
  height: 75vw;
}

.splitScreen_ordering__1z_YE, .splitScreen_orderingVertical__x-yA8 {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.splitScreen_orderingHorizontal__CIYDP .splitScreen_child__39ZuA {
  width: 100%;
}

.splitScreen_ordering__1z_YE, .splitScreen_orderingHorizontal__CIYDP {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.splitScreen_orderingHorizontal__CIYDP .splitScreen_child__39ZuA {
  width: 50%;
}


.howToPlay_instructionsText__2WwTM {
  text-align: center;
}

.howToPlay_scribblerPhone__2oYs1 {
  transform: rotate(-2deg)
}

.howToPlay_foregroundCompleterPhone__2kTbp {
  transform: translateY(-100%) translateX(12%) rotate(-1deg);
}

.howToPlay_backgroundCompleterPhone__AGrK4 {
  transform: translateX(23%) rotate(5deg) scale(0.95) translateY(1%);
}

.howToPlay_winnerPhone__3mwQF {
  transform: rotate(1.5deg)
}


.phone_phone__2I2mV {
  max-height: 57vh;
  object-fit: contain;
}

.phone_phoneDesktop__1-iZY {
  width: 65%;
}

.phone_phonePhone__1OhD5 {
  width: 60%;
}

.contact_contact__2D1z_ {
    background-color: transparent;
}

.wrap_wrap__GK5pB {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.contactLinks_socialMediaLink__1kONN {
    transition: all 0.1s cubic-bezier(.17, .67, .72, 1.11);
    cursor: pointer;
    object-fit: contain;
}

.contactLinks_socialMediaLink__1kONN:hover {
    transform: scale(1.1);
}

.boringStuff_boringStuff__1JlSF {
  opacity: 0.5;
}

.attribution_copyright__1JZ9i {
  opacity: 0.5;
}
.navLink_navLink__1fIE5 {
  padding-left: 50px;
  transition: all 0.1s cubic-bezier(.17, .67, .72, 1.11);
  text-decoration: none;
  font-weight: bold;
}

.navLink_navLink__1fIE5:hover {
  cursor: pointer;
  transform: scale(1.11)
}

.quote_quoteIcon__1kFpD {
  font-size: 50pt;
  height: 40pt;
  font-weight: bold;
}

.quote_quoteText__3LSim {
  font-size: 17pt;
  text-align: center;
}

.quote_quoteAttribution__1SAYB {
  font-size: 12pt;
  font-style: italic;
  opacity: 0.7;
}

.videoReview_videoReview__T2wpa {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 8px #00000015;
}

.videoReview_videoAttribution__1e3qj {
  font-size: 12pt;
  font-style: italic;
  opacity: 0.7;
}

.reviews_reviews__blwS_ {
  width: 100%;
}

.reviews_review__K4nNj {
  width: 100%;
}

/* desktop */
@media(min-width: 570px) {
  .reviews_review__K4nNj {
    width: 32%;
    padding: 8px;
  }
}
h3 {
    margin-top: 32px;
}

p {
    margin-top: 16px;
    margin-bottom: 16px;
}

li {
    margin-left: 32px;
}

[data-custom-class='body'],
[data-custom-class='body'] * {
    background: transparent !important;
}

[data-custom-class='title'],
[data-custom-class='title'] * {
    font-size: 33px !important;
    color: black !important;
}

[data-custom-class='subtitle'],
[data-custom-class='subtitle'] * {
    color: black !important;
    font-size: 21px !important;
}

[data-custom-class='heading_1'],
[data-custom-class='heading_1'] * {
    font-size: 26px !important;
    color: black !important;
}

[data-custom-class='heading_2'],
[data-custom-class='heading_2'] * {
    font-size: 24px !important;
    color: black !important;
}

[data-custom-class='body_text'],
[data-custom-class='body_text'] * {
    color: black !important;
    font-size: 16px !important;
}

[data-custom-class='link'],
[data-custom-class='link'] * {
    color: black !important;
    font-size: 16px !important;
    word-break: break-word !important;
}

ul {
    list-style-type: square;
}

ul>li>ul {
    list-style-type: circle;
}

ul>li>ul>li>ul {
    list-style-type: square;
}

ol li {
    font-family: Arial;
}

