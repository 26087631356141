
.videoReview {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 8px #00000015;
}

.videoAttribution {
  font-size: 12pt;
  font-style: italic;
  opacity: 0.7;
}