
.quoteIcon {
  font-size: 50pt;
  height: 40pt;
  font-weight: bold;
}

.quoteText {
  font-size: 17pt;
  text-align: center;
}

.quoteAttribution {
  font-size: 12pt;
  font-style: italic;
  opacity: 0.7;
}