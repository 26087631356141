
.page {
  width: 100%;
  position: relative;
}

.title {
  font-size: 25pt;
  margin-bottom: 8px;
  font-family: 'Josefin Sans', sans-serif;
  text-shadow: 0px 0px 8px #00000015;
}

/* desktop */

@media(min-width: 570px) {
  .title {
    font-size: 35pt;
  }
}

.border {
  position: absolute;
  width: 100%;
  transform: translate(0px, -50%);
  z-index: 0;
}
