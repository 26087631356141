@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&family=Lato&display=swap');
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #333333;
  color: white;
  font-family: 'Lato', sans-serif;
}

a {
  text-decoration: none;
  font-weight: bold;
  color: white;
}
