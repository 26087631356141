
.phone {
  max-height: 57vh;
  object-fit: contain;
}

.phoneDesktop {
  width: 65%;
}

.phonePhone {
  width: 60%;
}
