
.statHeader {
  font-weight: bold;
  font-size: max(9.0vw, 15pt);
  text-align: center;
}

@media(min-width: 570px) {
  .statHeader {
    font-size: max(min(4.0vw, 40pt), 30pt);
  }
}

.statSubheader {
  font-size: inherit;
  text-align: center;
}

.verticalDivider {
  height: 80px;
  width: 5px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.65);
}

@media(min-width: 570px) {
  .verticalDivider {
    width: 6px;
    height: 110px;
  }
}

.star {
  height: 23px;
}

@media(min-width: 570px) {
  .star {
    height: 43px;
  }
}