
.instructionsText {
  text-align: center;
}

.scribblerPhone {
  transform: rotate(-2deg)
}

.foregroundCompleterPhone {
  transform: translateY(-100%) translateX(12%) rotate(-1deg);
}

.backgroundCompleterPhone {
  transform: translateX(23%) rotate(5deg) scale(0.95) translateY(1%);
}

.winnerPhone {
  transform: rotate(1.5deg)
}
