
.ordering, .orderingVertical {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.orderingHorizontal .child {
  width: 100%;
}

.ordering, .orderingHorizontal {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.orderingHorizontal .child {
  width: 50%;
}
