
.reviews {
  width: 100%;
}

.review {
  width: 100%;
}

/* desktop */
@media(min-width: 570px) {
  .review {
    width: 32%;
    padding: 8px;
  }
}