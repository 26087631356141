
.carouselContainer {
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  white-space: nowrap;
  /* Hide scroll bars */
  -ms-overflow-style: none;
}

.carouselContainer::-webkit-scrollbar {
  display: none;
}

.call_to_action {
  text-align: center;
}