.navLink {
  padding-left: 50px;
  transition: all 0.1s cubic-bezier(.17, .67, .72, 1.11);
  text-decoration: none;
  font-weight: bold;
}

.navLink:hover {
  cursor: pointer;
  transform: scale(1.11)
}