
.drawings {
  background-color: #FAC130;
}

.carouselContainer {
  overflow-x: scroll;
  width: 100%;
  white-space: nowrap;
  /* Hide scroll bars */
  -ms-overflow-style: none;
}

.carouselContainer::-webkit-scrollbar {
  display: none;
}

.drawingContainer {
  display: inline-block;
}

.drawingTitle {
  font-weight: bold;
}

.drawing {
  background-color: white;
  border-radius: 8px;

  min-width: 180px;
  min-height: 180px;

  max-width: 400px;
  max-height: 400px;

  box-shadow: 0px 0px 8px #00000015;
}

.drawingDesktop {
  width: 25vw;
  height: 25vw;
}

.drawingPhone {
  width: 75vw;
  height: 75vw;
}