
.appName {
  font-weight: bold;
  font-size: 18pt;
  color: black;
}

.appIcon {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 0px 8px #00000015;
}