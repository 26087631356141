
.badge {
  height: 58px;
  object-fit: contain;
}

/* Desktop */
@media(min-width: 570px) {
  .badge {
    height: 70px;
  }
}
